<template>
	<div class="help-index-page">

		<form action="/">
			<van-search
				v-model="keyword"
				show-action
				:placeholder="translate('please_enter_the_search_keyword')"
				@search="onSearch"
				@cancel="onCancel"
			/>
		</form>

		<van-row class="help-content">
			<div v-for="(item, index) in lists" :key="index">
				<van-col span="24" class="category-item">{{item.name}}</van-col>
				<van-col span="24" class="help-item" v-for="child in item.children" :key="child.id"  @click="goInfo(child.id)">{{child.name}}</van-col>
			</div>
		</van-row>

	</div>
</template>

<script>
	export default {
		name: 'help_index',
		data() {
			return {

				keyword: "",

				lists: [],
			}
		},
		mounted() {
			this.onLoadData()
		},
		methods: {
			onLoadData(){
				//根据TOKEN获取信息
				this.$axios({
					method: 'post',
					url: 'help/lists',
					data: {
						keyword: this.keyword
					}
				}).then ((res) => {
					if (res.success) {

						this.lists = res.data
						
					} else {
						this.$toast.fail(res.error_text)
					}
				})
			},

			onSearch(val) {
				this.keyword = val

				this.onLoadData()
			},

			onCancel() {

				this.keyword = ""

				this.onLoadData()
			},

			goInfo(id) {
				this.$router.push('/help/info?id=' + id) 
			}
		}
	}
</script>